const urls = {
  // dev: "https://staging.foodnerd.pk"
  production: 
    process.env.NODE_ENV === 'staging' ? "https://staging.foodnerdpos.com" : 
    (process.env.NODE_ENV === 'dev' ? "https://localhost:3000" : "https://www.foodnerdpos.com"),
  development: "https://www.foodnerdpos.com",
  // production: "https://staging.foodnerdpos.com",
  // development: "https://staging.foodnerdpos.com",
  // production: "http://localhost:3000",
  // development: "http://localhost:3000",
  test: "https://staging.foodnerdpos.com"
};

// Your web app's Firebase configuration
export const firebaseConfig = {
  senderId: "359007590162",
  init: {
    apiKey: "AIzaSyCE7p16ERHNQ7MygleUqsCgbJDjrEh_yVM",
    authDomain: "foodnerd-79f40.firebaseapp.com",
    databaseURL: "https://foodnerd-79f40.firebaseio.com",
    projectId: "foodnerd-79f40",
    storageBucket: "foodnerd-79f40.appspot.com",
    messagingSenderId: "359007590162",
    appId: "1:359007590162:web:d3fc9b7f8aa17a5f"
  },
  vapidKey: "BOUyP1TBxHJVdW4orurGcLoby1A3LFcwzuSp8L6vchfHH3Oo9tL9fwV07nPBJx8wzBsszfzRSHaC5vSntsvLPJk"
};
const ENV = process.env.NODE_ENV;
export const baseUrl = urls[ENV];
export let isKebabifyWeb = false;
let isDevMode = false;
let isPOS = false;
let isManager = false;
if (typeof window !== "undefined" && window.location) {
  const { hostname, host } = window.location;
  isKebabifyWeb = hostname === "www.foodnerdpos.com" || hostname === "staging.foodnerd.pk";
  // if (host === "localhost:3000") {
  //   baseUrl = "";
  // }
  isDevMode = process.env.REACT_APP_ENV === "dev" || hostname === "localhost";
  isPOS = hostname === "pos.foodnerd.pk";
  isManager = hostname === "www.foodnerdpos.com" || hostname === "staging.foodnerd.pk" || host === "localhost:3000";
}
// console.log("env:", ENV, baseUrl);
export const constants = {
  baseURL: baseUrl,
  apiURL: `${baseUrl}/api`,
  managerURL: `${baseUrl}/manager`,
  s3URL: 'https://foodnerd.s3.eu-west-1.amazonaws.com',
  webposURL: `/manager/pos`,
  googleMapsURL: "https://maps.googleapis.com/maps/api",
  isDevMode,
  isPOS,
  isManager,
  localHost: 'http://localhost:3002',
  languages: {
    en: {
      code: "en",
      title: "English",
      flag: "en"
    },
    ur: {
      code: "ur",
      title: "اردو",
      flag: "pk"
    },
    ar: {
      code: "ar",
      title: "عربى",
      flag: "sa"
    },
    de: {
      code: "de",
      title: "danish",
      flag: "de"
    }
  },

  HTTPStatusCode: {
    100: "Continue",
    101: "Switching Protocols",
    102: "Processing",
    200: "OK",
    201: "Created",
    202: "Accepted",
    203: "Non-Authoritative Information",
    204: "No Content",
    205: "Reset Content",
    206: "Partial Content",
    300: "Multiple Choices",
    301: "Moved Permanently",
    302: "Found",
    303: "See Other",
    304: "Not Modified",
    305: "Use Proxy",
    306: "Unused",
    307: "Temporary Redirect",
    308: "Permanent Redirect",
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Request Entity Too Large",
    414: "Request-URI Too Long",
    415: "Unsupported Media Type",
    416: "Requested Range Not Satisfiable",
    417: "Expectation Failed",
    418: "I'm a teapot",
    422: "Unprocessable Entity",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    451: "Unavailable For Legal Reasons",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    511: "Network Authentication Required",
    520: "Web server is returning an unknown error",
    522: "Connection timed out",
    524: "A timeout occurred"
  },
  PRINT_TYPES: {
    SINGLE_PRINT: "single",
    DELTA_PRINT: "delta",
    BREAK_PRINT: "break",
    PRINT_RECEIPT: "print",
    TOKEN_RECEIPT: "TOKEN_RECEIPT",
    submit_print_kitchen: "submit_print_kitchen",
    submit_print_receipt: "submit_print_receipt",
    pay_submit_print: "pay_submit_print",
    pay_submit_print_break: "pay_submit_print_break",
    submit_btn: "submit_btn",
    token_receipt: "token_receipt",
    preview: "preview",
    send_to_kitchen: "send_to_kitchen",
    BREAK_ITEM_PRINT: "break_item"
  },
  ORDER_MODE_TYPE: {
    EAT_IN: "eat_in",
    EAT_IN_2: "eat-in", //for backward compatibility
    PICKUP: "pickup",
    DELIVERY: "delivery"
  },
  ORDER_CHANNEL: {
    POS: "pos",
    CHEETAY_POS: "cheetay",
    FOODPANDA_POS: "foodpanda",
    JOVI_POS: "jovi"
  },
  CHANNEL_HUMANIZE: {
    POS: "pos",
    CHEETAY: "cheetay",
    FOODPANDA: "foodpanda",
    JOVI: "jovi"
  },
  DISCOUNT_TYPE: {
    PERCENTAGE: "%",
    VALUE: "value"
  },
  HUMANIZE: {
    ORDER_MODE_TYPE: {
      eat_in: "Eat In",
      pickup: "Pickup",
      delivery: "Delivery"
    }
  },
  QTY_CHANGE_TYPE: {
    DECREMENT: "decrement",
    INCREMENT: "increment"
  },
  PAYMENT_METHODS: {
    CASH: "CASH",
    CREDIT_CARD: "CREDIT CARD",
    DNA: "DNA",
    DEJAVOO: "DEJAVOO",
    NEARPAY: "NEARPAY",
    SPLIT_PAYMENT: "SPLIT PAYMENT",
    JAZZ_CASH: "JAZZ CASH",
    EASYPAISA: "EASY PAISA",
  },
  NOTIFICATION_SOUND: {
    PLAY: "play",
    PAUSE: "pause",
    MUTE: "mute",
    UNMUTE: "unmute"
  }
};

export const PaymentTypes = {
  CREDIT_CARD: "Payment::Ccard",
  CARD: "Payment::Card",
  CASH: "Payment::Cash",
  DNA: "Payment::DnaPayment",
  NEARPAY: "Payment::Nearpay",
  JAZZCASH: "Payment::JazzCash",
  EASYPAISA: "Payment::EasyPaisa",
  DEJAVOO: "Payment::DejavooPayment"
};

export const PrintReceiptTypes = {
  SUBMIT: "print_receipt_submit",
  PRINT: "print_receipt",
  TEST: "print_receipt_test",
}

export const domImageOptions = {
  width: 600,
  style: {
    '-moz-transform': 'scale(1)',
    '-moz-transform-origin': '0 0',
    '-o-transform': 'scale(1)',
    '-o-transform-origin': '0 0',
    '-webkit-transform': 'scale(1)',
    '-webkit-transform-origin': '0 0',
    'transform': 'scale(1)',
    'transform-origin': '0 0',
    'page-break-after': 'always'
  }
}

export const qzConfigOptions = {
  altPrinting: false,
  // interpolation: "nearest-neighbor",
  colorType: "grayscale"
}

export const LabelPrinterTypes = {
  EPL: "epl",
  ZPL: "zpl",
  TSPL: "tspl"
}

export const zatcaSolutionName = 'FoodnerdPOS'