import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import GiftCardClaim from "./GiftCardClaim";
import TermsAndConditions from "./TermsAndConditions";
import AppLayout from "./AppLayout";
import TextField from "@mui/material/TextField";
import UserResource from "../resources/user";
import sweetalert2 from "sweetalert2";
import { I18nRails } from "../../shared/rails-i18n-js";
import Grid from "@mui/material/Grid";
import * as rh from "./helpers/routes";
import UserPhoneResource from "../resources/user-phone";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CardGiftCardIcon from "@mui/icons-material/CardGiftcard";
import ExitToApp from "@mui/icons-material/ExitToApp";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import axios from "axios";
import LockIcon from "@mui/icons-material/Lock";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationSettings from "./NotificationSettings";
import CircularProgress from "@mui/material/CircularProgress";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { isLoggedIn, loadImage } from "./helpers/utils";
import { location_header } from "../models/location";
import withParent from "./hoc/withParent";
import { isUserTokenSaved, isUserTokenSet } from "../models/user";
import { getApiHeaders } from "../utils/helpers";

const AppProfile = ({
  Rails,
  user,
  setUser,
  loadUser,
  isRequiredPhoneVerification,
  logout,
  fndLocation,
  basket,
  countryConfig,
  showResourceError,
  showResourceResponseError,
  ...other
}) => {
  const [pincode, setPincode] = useState("");
  const [pincodeConfirm, setPincodeConfirm] = useState("");
  const [currentPincode, setCurrentPincode] = useState("");
  const [currentPincode2, setCurrentPincode2] = useState("");
  const [giftCards, setGiftCards] = useState([]);
  const [updateEmail, setUpdateEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirm, setPassword_confirm] = useState("");
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingActi, setLoadingActi] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [pinSent, setPinSent] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [phone_number, setPhone_number] = useState("");

  const [notificationSettings, setNotificationSettings] = useState([
    {
      name: "notifications_push",
      label: I18nRails.t("Web.notifications"),
      value: true,
    },
    {
      name: "order_acceptance_push",
      label: I18nRails.t("Web.order_acceptance"),
      value: true,
    },
    {
      name: "order_delivery_push",
      label: I18nRails.t("Web.order_delivery"),
      value: true,
    },
    {
      name: "order_rejected_push",
      label: I18nRails.t("Web.order_rejected"),
      value: true,
    },
    {
      name: "notifications_email",
      label: I18nRails.t("Web.notifications"),
      value: true,
    },
    {
      name: "order_acceptance_email",
      label: I18nRails.t("Web.order_acceptance"),
      value: true,
    },
    {
      name: "order_delivery_email",
      label: I18nRails.t("Web.order_delivery"),
      value: true,
    },
    {
      name: "order_rejected_email",
      label: I18nRails.t("Web.order_rejected"),
      value: true,
    },
    {
      name: "notifications_sms",
      label: I18nRails.t("Web.notifications"),
      value: true,
    },
    {
      name: "order_acceptance_sms",
      label: I18nRails.t("Web.order_acceptance"),
      value: true,
    },
    {
      name: "order_delivery_sms",
      label: I18nRails.t("Web.order_delivery"),
      value: true,
    },
    {
      name: "order_rejected_sms",
      label: I18nRails.t("Web.order_rejected"),
      value: true,
    },
  ]);

  const [num_favorites, setNumFavorites] = useState({
    Location: 0,
    MenuItem: 0,
  });

  const [timer, setTimer] = useState();
  const [updating_user_error, setUpdating_user_error] = useState();
  const [updated_user, setUpdated_user] = useState();
  const [canRequestToken, setCanRequestToken] = useState();

  const isPhoneVerificationRequired=() => {
    return !!Rails.require_phone_verification;
  }

  const fetchGiftCards = () => {
    GiftCardResource.get(Rails).query((giftCards) => {
      setGiftCards(giftCards);
    });
  };

  const validateHandler = (values) => {
    const errors = {};
    if (
      !values.phone_number ||
      !/^(\+|00)92\d{10}$/.test(values.phone_number)
    ) {
      errors.phone_number = "Format: +92xxxxxxxxxx";
    }
    return errors;
  };

  const activatePhone = (values, options) => {
    if (!!!timer) {
      // const { phone_number } = this.state;

      UserPhone.current.create(
        { user: { phone: values.phone_number } },
        (response) => {
          if (response.ok) {
            sweetalert2.fire(
              I18nRails.t("client.profile.pincode.phone_activated")
            );
            if (!!Rails.require_phone_verification) {
              setPinSent(true);
            } else {
              setUser({ ...user, confirmed_phone: values.phone_number });
            }
          } else {
            sweetalert2.fire(
              I18nRails.t("client.profile.pincode.phone_activated_error")
            );
          }
        }
      );

      if (!!Rails.require_phone_verification) {
        setCanRequestToken(false);
        setTimer(30);
        setTimeout(() => {
          if (timer < 1) {
            setCanRequestToken(true);
            setTimer(null);
          } else {
            setTimer(timer - 1);
          }
        }, 1000);
      } else {
        setUser({ ...user, confirmed_phone: values.phone_number });
      }
    }
  };

  const authorizePhone = (phone_number, pincode, event) => {
    if (!pincode || pincode.length < 4) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pin"));
      return;
    }

    const verify_phone = {
      id: "last",
      phone: phone_number,
      token: pincode,
    };
    UserPhone.current.update(
      verify_phone,
      (success) => {
        sweetalert2.fire(I18nRails.t("client.profile.pincode.phone_verified"));
        setUser({ ...user, confirmed_phone: phone_number });
      },
      (error) => {
        sweetalert2.fire(
          I18nRails.t("client.profile.pincode.phone_activated_error")
        );
        showResourceError(error);
      }
    );
  };

  const getHeaders = () => {
    const headers = {
      "Time-Zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (!!Rails.access_token) {
      headers["Authorization"] = `Bearer ${Rails.access_token}`;
    }
    return headers;
  };

  const deactivatePin = (event) => {
    setLoadingActi(true);
    // event.preventDefault();
    if (!currentPincode2 || currentPincode2.length < 4) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pin"));
      return;
    }

    const token_update = {
      token_confirmation: currentPincode2,
      use_tokens: false,
      current_token: currentPincode2,
    };

    const URL = `/api/users/` + user.id + ".json";

    axios
      .put(URL, token_update, { headers: getApiHeaders() })
      .then((response) => {
        sweetalert2.fire(I18nRails.t("venue.alertmessage.pin_deactivated"));
        //timeout navigate
        loadingActi(false);
        setTimeout(() => {
          window.location.href = `${me.Rails.host}`;
        }, 1000);
      })
      .catch((error) => {
        loadingActi(false);
        if (error.response) {
          sweetalert2.fire(error.response.data.errors.join("\n"));
        }
      });
  };

  const activatePin = (event) => {
    setLoadingActi(true);
    event.preventDefault();
    if (
      !pincode ||
      !pincodeConfirm ||
      pincode.length !== 4 ||
      pincodeConfirm.length !== 4
    ) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pin")); //, event.target.parentElement)
      return;
    } else if (pincode != pincodeConfirm) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pindont")); //, event.target.parentElement)
      return;
    } else {
      const token_update = {
        token_new: pincode,
        token_confirmation: pincodeConfirm,
        use_tokens: true,
        current_token: "undefined",
      };

      const URL = `/api/users/` + user.id + ".json";
      return axios
        .put(URL, token_update, { headers: getApiHeaders() })
        .then((response) => {
          console.log("response", response);
          setLoadingActi(false);
          sweetalert2.fire(I18nRails.t("venue.alertmessage.pin_activated"));
          setTimeout(() => {
            window.location.href = `${me.Rails.host}`;
          }, 1000);
        })
        .catch((error) => {
          setLoadingActi(false);
          if (error.response) {
            sweetalert2.fire(error.response.data.errors.join("\n"));
            console.error("API users error", error);
          }
        });
    }
  };

  const updatePin = (event) => {
    setLoadingChange(true);
    event.preventDefault();
    if (!currentPincode || !pincode || !pincodeConfirm) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pin"));
      return;
    }
    if (pincode !== pincodeConfirm) {
      sweetalert2.fire(I18nRails.t("venue.alertmessage.pin"));
      return;
    } else {
      const token_update = {
        token_new: pincode,
        token_confirmation: pincodeConfirm,
        use_tokens: true,
        current_token: currentPincode,
      };

      const URL = `/api/users/` + user.id + ".json";
      axios
        .put(URL, token_update, { headers: getApiHeaders() })
        .then((response) => {
          sweetalert2.fire(I18nRails.t("venue.alertmessage.pin_updated"));
          //timeout navigate
          setLoadingChange(false);
          setTimeout(() => {
            window.location.href = `${me.Rails.host}`;
          }, 1000);
        })
        .catch((error) => {
          setLoadingChange(false);
          if (error.response) {
            sweetalert2.fire(error.response.data.errors.join("\n"));
          }
        });
    }
  };

  const updateUser = (user, event) => {
    event.preventDefault();
    UserResource.get(Rails).update(
      user,
      (user) => {
        sweetalert2.fire({
          text: I18nRails.t("client.profile.information_updated"),
          icon: "success",
        });
        setUser(user);
        setUpdated_user(true);
      },
      (error) => {
        sweetalert2.fire({
          text: "error updating information!",
          icon: "error",
        });
        setUpdating_user_error(true);
      }
    );
  };

  const setUserField = (key, e) => {
    setUser({ ...user, [key]: e.target.value });
  };

  const updateUserEmail = (e) => {
    if (user.email == updateEmail) {
      sweetalert2.fire(I18nRails.t("client.profile.old_email"));
      return;
    }
    const URL = `/api/users/` + user.id + ".json";
    var email = { email: updateEmail };
    console.log("updateEmail", email);
    axios
      .put(URL, email, { headers: getApiHeaders() })
      .then((response) => {
        console.log("response", response);
        sweetalert2.fire(I18nRails.t("client.profile.changed_email"));
      })
      .catch((error) => {
        sweetalert2.fire(error.response.data.errors.join("\n"));
      });
  };

  const changePassword = (e) => {
    if (currentPassword == "") {
      sweetalert2.fire(I18nRails.t("client.profile.cpass_missing"));
      return;
    }
    if (
      password != password_confirm ||
      password_confirm == "" ||
      password == ""
    ) {
      sweetalert2.fire(I18nRails.t("client.profile.pass_mismatch"));
      return;
    }

    var data = {
      user: {
        currentPassword: currentPassword,
        password: password,
        password_confirmation: password_confirm,
      },
    };
    const URL = `/api/users/` + user.id + ".json";

    return axios
      .put(URL, data, { headers: getApiHeaders() })
      .then((response) => {
        console.log("response", response);
        sweetalert2.fire(I18nRails.t("client.profile.changed_password"));
      })
      .catch((error) => {
        console.error("Api users error", error);
        sweetalert2.fire(error.response.data.errors.join("\n"));
      });
  };

  const handleSwitchChange = (e) => {
    setNotificationSettings(
      notificationSettings.map((item) =>
        item.name === e.target.name
          ? { ...item, value: e.target.checked }
          : item
      )
    );
  };

  const updateNotificationSettings = () => {
    var dataToSend = {
      user: {
        location_id: "customer",
        notification_settings: {
          customer: {
            devices: {
              notifications_push: notificationSettings.find(
                ({ name }) => name === "notifications_push"
              ).value,
              order_acceptance_push: notificationSettings.find(
                ({ name }) => name === "order_acceptance_push"
              ).value,
              order_delivery_push: notificationSettings.find(
                ({ name }) => name === "order_delivery_push"
              ).value,
              order_rejected_push: notificationSettings.find(
                ({ name }) => name === "order_rejected_push"
              ).value,
              notifications_email: notificationSettings.find(
                ({ name }) => name === "notifications_email"
              ).value,
              order_acceptance_email: notificationSettings.find(
                ({ name }) => name === "order_acceptance_email"
              ).value,
              order_delivery_email: notificationSettings.find(
                ({ name }) => name === "order_delivery_email"
              ).value,
              order_rejected_email: notificationSettings.find(
                ({ name }) => name === "order_rejected_email"
              ).value,
              notifications_sms: notificationSettings.find(
                ({ name }) => name === "notifications_sms"
              ).value,
              order_acceptance_sms: notificationSettings.find(
                ({ name }) => name === "order_acceptance_sms"
              ).value,
              order_delivery_sms: notificationSettings.find(
                ({ name }) => name === "order_delivery_sms"
              ).value,
              order_rejected_sms: notificationSettings.find(
                ({ name }) => name === "order_rejected_sms"
              ).value,
            },
          },
        },
      },
    };
    const URL =
      `/api/users/` + user.id + "update_user_notifications.json";
    axios
      .put(URL, dataToSend, { headers: getApiHeaders() })
      .then((response) => {
        if (response) {
          console.log("response", response);
          sweetalert2.fire("Updated Successfully");
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const renderLocale = (locale) => {
    return (
      <FormControl style={{ width: "30%" }}>
        <InputLabel id="demo-simple-select-label">
          {I18nRails.t("Web.language")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={locale}
          onChange={(e) => setUserField("locale", e)}
        >
          <MenuItem value={""}>Default</MenuItem>
          <MenuItem value={"en-PK"}>English</MenuItem>
          {/* <MenuItem value={"ur"}>اردو</MenuItem> */}
          <MenuItem value={"ar"}>عربى</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const children = ({ values, errors, touched, isSubmitting, isValidating }) => {
    return (
      <Form method="post" className="form-signin login-form">
        <Grid container>
          <Grid xs={4} item>
            <Field
              type="text"
              name="phone_number"
              placeholder={I18nRails.t("Web.format")}
              className="browser-default form-control login_username"
              style={{ color: "red !important" }}
            />
            <ErrorMessage
              name="phone_number"
              component="validation-message"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={3} item>
            <br />
            <Button
              className="btn-first"
              style={{ width: "100%" }}
              disabled={Object.keys(errors).length}
              onSubmit={activatePhone}
              type="submit"
            >
              {I18nRails.t("merchant.submit")}
            </Button>
          </Grid>
        </Grid>
      </Form>
    );
    //   return pug`
    //     Form(method='post').form-signin.login-form
    //       Grid(container)
    //         Grid(xs=4 item)
    //           Field.browser-default.form-control.login_username(type='text', name='phone_number', placeholder=${ I18nRails.t('Web.format') },  style={color:'red !important'})
    //           ErrorMessage(name='phone_number', component='validation-message')
    //       Grid(container)
    //         Grid(xs=3 item)
    //           br
    //           Button.btn-first(style={width:'100%'}, disabled=Object.keys(errors).length, onSubmit=this.activatePhone, type='submit')= I18nRails.t('merchant.submit')
    // `;
  };

  const UserPhone = useRef(UserPhoneResource.get(Rails));

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
    if (user) {
      setLoadingProfile(false);
      //setting inital values for notifications
      for (let i = 0; i < notificationSettings.length; i++) {
        var devName = notificationSettings[i].name.toString();
        if (
          user &&
          user.notification_settings &&
          user.notification_settings.customer &&
          user.notification_settings.customer.devices
        ) {
          notificationSettings[i].value =
            user.notification_settings.customer.devices[devName];
        }
      }
      if (!!user.addresses && user.addresses.length > 0) {
        setAddresses(user.addresses);
        setUpdateEmail(user.email);
      }

      if (
        !!Rails.require_phone_verification &&
        !user.confirmed_phone &&
        (user.token_status === "set" || user.token_status === "saved")
      ) {
        setPinSent(true);
      }

      for (let favorite of user.favorites) {
        if (!num_favorites[favorite.obj_type]) {
          num_favorites[favorite.obj_type] = 0;
        }
        num_favorites[favorite.obj_type] =
          num_favorites[favorite.obj_type] + 1;
      }            
      setUserImage(user.user_image_url || "");

      setPhone_number(
        user.confirmed_phones.length > 0
          ? user.confirmed_phones[user.confirmed_phones.length - 1]
          : ""
      );
    } else {
      loadUser((user) => {
        if (!!user.addresses && user.addresses.length > 0) {
          setAddresses(user.addresses);
          setUpdateEmail(user.email);
        }
        if (
          !!Rails.require_phone_verification &&
          !user.confirmed_phone &&
          (user.token_status === "set" || user.token_status === "saved")
        ) {
          setPinSent(true);
        }
        for (let favorite of user.favorites) {
          if (!num_favorites[favorite.obj_type]) {
            num_favorites[favorite.obj_type] = 0;
          }
          num_favorites[favorite.obj_type] =
            num_favorites[favorite.obj_type] + 1;
        }
        setNumFavorites(num_favorites);
        setUserImage(user.user_image_url || "");
        setLoadingProfile(false);
        setPhone_number(user.confirmed_phone);
      });
    }
  }, []);

  const backgroundImage =
    !!fndLocation && !!location_header(fndLocation)
      ? location_header(fndLocation)
      : loadImage("orderNowBackground.jpg");
  return (
    <Grid item xs={12}>
      <AppLayout 
        Rails={Rails} 
        user={user} 
        countryConfig={countryConfig}
        {...other}
      >
        {user && (
          <>
            <div
              style={{
                paddingTop: "20% !important",
                backgroundImage: `url(${backgroundImage})`,
              }}
              className="dashboard-bg-img"
            >
              <Grid container>
                <Grid item xs={2} style={{ padding: "4" }}>
                  <img
                    className="center circle"
                    src={userImage}
                  />
                </Grid>
                <Grid item xs={8}>
                  <h3 className="white-text">
                    {user.first_name} {user.last_name}
                  </h3>
                  <div className="profile-heart">
                    {num_favorites.Location && (
                      <>
                        <i className="fas fa-heart"></i>
                        <span
                          style={{ marginRight: "3%" }}
                          className="profileHeader"
                        >
                          {num_favorites.Location}{" "}
                          {I18nRails.t("client.profile.places")}
                        </span>
                      </>
                    )}
                    {num_favorites.MenuItem && (
                      <>
                        <i className="fas fa-heart"></i>
                        <span className="profileHeader">
                          {num_favorites.MenuItem}{" "}
                          {I18nRails.t("client.profile.dishes")}
                        </span>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Grid container>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <AccountBoxIcon color="primary" />
                    <h5 className="custom-accordion-header">
                      {I18nRails.t("profile.personal_info")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails className="column-flex">
                    <form
                      className="personal-form"
                      name="user"
                      onSubmit={(e) => updateUser(user, e)}
                    >
                      {updating_user_error && (
                        <span>
                          There was an error updating user please try again
                        </span>
                      )}
                      {updated_user && <span>User updated</span>}
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label={I18nRails.t("client.profile.first_name")}
                            style={{ width: "30%" }}
                            type="text"
                            value={user.first_name}
                            onChange={(e) => setUserField("first_name", e)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label={I18nRails.t("client.profile.last_name")}
                            style={{ width: "30%" }}
                            type="text"
                            value={user.last_name}
                            onChange={(e) => setUserField("last_name", e)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label={I18nRails.t("client.profile.phone_number")}
                            style={{ width: "30%" }}
                            type="text"
                            value={user.phone}
                            placeholder="(Format: 03001231231 | +923001231231)"
                            onChange={(e) => setUserField("phone", e)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {renderLocale(user.locale)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2}>
                          <Button
                            type="submit"
                            style={{ width: "100%", marginTop: "3%" }}
                            className="btn-first"
                          >
                            {I18nRails.t("client.profile.update_info")}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            label={I18nRails.t("client.profile.current_email")}
                            style={{ width: "30%", marginTop: "2%" }}
                            type="text"
                            onChange={(e) => setUpdateEmail(e.target.value)}
                            value={updateEmail}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            onClick={(e) => updateUserEmail(e)}
                            style={{ width: "100%", marginTop: "3%" }}
                            className="btn-first"
                          >
                            {I18nRails.t("client.profile.update_email")}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </AccordionDetails>
                </Accordion>
                <Accordion onSelect={(e) => fetchGiftCards()}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <CardGiftCardIcon color="primary" />
                    <h5 className="custom-accordion-header">
                      {I18nRails.t("client.profile.gift_card.heading")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails className="column-flex">
                    <p className="text-center profile-info">
                      {I18nRails.t("client.profile.gift_card.text")}
                    </p>
                    <GiftCardClaim 
                      Rails={Rails} 
                      showResourceError={showResourceError} 
                      showResourceResponseError={showResourceResponseError}
                    />
                    <table>
                      {!!giftCards &&
                        giftCards.map((giftCard) => (
                          <tr>
                            <td>{giftCard.id}</td>
                            <td>{giftCard.start_amount}</td>
                            <td>{giftCard.start_credits}</td>
                            <td>{giftCard.status}</td>
                          </tr>
                        ))}
                    </table>
                    {user.token_status !== "set" ? (
                      <h2>{I18nRails.t("client.profile.pincode.activate")}</h2>
                    ) : (
                      <h2>{I18nRails.t("client.profile.pincode.edit")}</h2>
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <PlaceIcon color="primary" />
                    <h5 className="custom-accordion-header">
                      {I18nRails.t("client.profile.pincode.activate")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails className="column-flex">
                    {!user.confirmed_phone && !pinSent && (
                      <div>
                        {isRequiredPhoneVerification() ? (
                          <p className="text-center">
                            {I18nRails.t(
                              "client.profile.phone.enter_and_verify"
                            )}
                          </p>
                        ) : (
                          <p className="text-center">
                            {I18nRails.t("client.profile.phone.enter")}
                          </p>
                        )}
                        <br />
                        <Formik
                          login-card-mui
                          initialValues={{ phone_number: phone_number }}
                          validate={validateHandler}
                          onSubmit={activatePhone}
                        >
                          {children}
                        </Formik>
                      </div>
                    )}
                    {isPhoneVerificationRequired() &&
                      !isUserTokenSet(user) &&
                      !!isUserTokenSaved(user) &&
                      !!pinSent && (
                        <div>
                          <form
                            onSubmit={(e) =>
                              authorizePhone(phone_number, pincode, e)
                            }
                          >
                            <p className="text-center">
                              {I18nRails.t(
                                "client.profile.phone.use_pin_to_auth"
                              )}
                            </p>
                            <TextField
                              label="Pin code"
                              style={{ width: "100%" }}
                              type="text"
                              value={pincode}
                              inputProps={{ maxLength: 4 }}
                              onChange={(e) => setPincode(e.target.value)}
                            />
                            <br />
                            <Button
                              className="btn-first"
                              onClick={(e) =>
                                authorizePhone(phone_number, pincode, e)
                              }
                            >
                              {I18nRails.t("client.profile.phone.auth_phone")}
                            </Button>
                            |
                            {(!!timer || !!canRequestToken) && (
                              <Button
                                className={`btn-second ${
                                  !!timer ? "disabled" : ""
                                }`}
                              >
                                {!!timer && <span>{timer} Sec</span>}
                                {!!canRequestToken && (
                                  <span>
                                    {I18nRails.t("confirm_phone.resend_pin")}
                                  </span>
                                )}
                              </Button>
                            )}
                          </form>
                        </div>
                      )}
                    {user.confirmed_phone &&
                      !isUserTokenSet(user) && (
                        <form onSubmit={(e) => activatePin(e)}>
                          <p className="text-center">
                            {I18nRails.t(
                              "client.profile.pincode.this_will_activate"
                            )}
                          </p>
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="Pin code"
                            style={{ width: "100%" }}
                            type="password"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                          />
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="Confirm Pin"
                            style={{ width: "100%" }}
                            type="password"
                            value={pincodeConfirm}
                            onChange={(e) => setPincodeConfirm(e.target.value)}
                          />
                          <div>
                            <Button
                              type="submit"
                              variant="contained"
                              className="btn-second"
                              disabled={
                                loadingActi ||
                                pincode == "" ||
                                pincodeConfirm == ""
                              }
                              style={{
                                marginTop: "2%",
                                paddingTop: "9px",
                                paddingBottom: "9px",
                              }}
                            >
                              {I18nRails.t("client.profile.pincode.set")}
                              {loadingActi && <CircularProgress size={24} />}
                            </Button>
                          </div>
                        </form>
                      )}
                    {user.confirmed_phone &&
                      isUserTokenSet(user) && (
                        <form>
                          <p className="text-center">
                            {I18nRails.t("client.profile.pincode.change_pin")}
                          </p>
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="Current Pin"
                            style={{ width: "100%" }}
                            type="password"
                            value={currentPincode}
                            onChange={(e) => setCurrentPincode(e.target.value)}
                          />
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="New Pin"
                            style={{ width: "100%" }}
                            type="password"
                            value={pincode}
                            onChange={(e) => setPincode(e.target.value)}
                          />
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="Confirm Pin"
                            style={{ width: "100%" }}
                            type="password"
                            value={pincodeConfirm}
                            onChange={(e) => setPincodeConfirm(e.target.value)}
                          />
                          <div>
                            <Button
                              variant="contained"
                              className="btn-first"
                              disabled={
                                loadingChange ||
                                currentPincode == "" ||
                                pincode == "" ||
                                pincodeConfirm == ""
                              }
                              style={{ marginTop: "2%" }}
                              onClick={(e) => updatePin(e)}
                            >
                              {I18nRails.t("client.profile.pincode.edit")}
                              {loadingChange && <CircularProgress size={24} />}
                            </Button>
                          </div>
                          <br />
                          <p className="text-center">
                            {I18nRails.t(
                              "client.profile.pincode.deactivate_pin"
                            )}
                          </p>
                          <TextField
                            inputProps={{ maxLength: 4 }}
                            label="Current Pin"
                            style={{ width: "100%" }}
                            type="password"
                            value={currentPincode2}
                            onChange={(e) =>
                              setCurrentPincode2(e.target.value)
                            }
                          />
                          <div>
                            <Button
                              variant="contained"
                              className="btn-second"
                              disabled={loadingActi || currentPincode2 == ""}
                              style={{ marginTop: "2%" }}
                              onClick={(e) => deactivatePin(e)}
                            >
                              {I18nRails.t("client.profile.pincode.deactivate")}
                              {loadingActi && <CircularProgress size={24} />}
                            </Button>
                          </div>
                        </form>
                      )}
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <EmailIcon color="primary" />
                    <h5 className="custom-accordion-header">
                      {I18nRails.t("display.help.title")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <p style={{ marginTop: "0px", paddingTop: "0px" }}>
                          {I18nRails.t("display.help.problems")}
                          {I18nRails.t("display.help.respond")}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <span className="note">
                          {I18nRails.t("display.help.contact_here")}
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className="btn btn-first"
                          style={{ marginTop: "2%", width: "40px" }}
                        >
                          <a
                            style={{ color: "white", width: "40%" }}
                            href={"mailto:" + countryConfig.mailto}
                          >
                            {I18nRails.t("client.profile.email")}
                          </a>
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <NotificationsIcon color="primary" />
                    <h5 className="custom-accordion-header">
                      {I18nRails.t("client.profile.notifications_settings")}
                    </h5>
                  </AccordionSummary>
                  <AccordionDetails className="column-flex">
                    <NotificationSettings
                      notificationSettings={notificationSettings}
                      handleSwitchChange={handleSwitchChange}
                      updateNotificationSettings={updateNotificationSettings}
                    />
                  </AccordionDetails>
                </Accordion>
                {isLoggedIn(Rails) && (
                  <>
                    <Accordion style={{ cursor: "pointer" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <LockIcon color="primary" />
                        <h5 className="custom-accordion-header">
                          {I18nRails.t("client.profile.change_password")}
                        </h5>
                      </AccordionSummary>
                      <AccordionDetails className="column-flex">
                        <form>
                          <TextField
                            label={I18nRails.t(
                              "client.profile.currentPassword"
                            )}
                            style={{ width: "100%" }}
                            type="password"
                            onChange={(e) =>
                              setCurrentPassword(e.target.value)
                            }
                          />
                          <TextField
                            label={I18nRails.t("client.profile.password")}
                            style={{ width: "100%" }}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <TextField
                            label={I18nRails.t("client.profile.password")}
                            style={{ width: "100%" }}
                            type="password"
                            onChange={(e) =>
                              setPassword_confirm(e.target.value)
                            }
                          />
                          <Button
                            className="btn-second"
                            onClick={(e) => changePassword(e)}
                            style={{ marginTop: "2%" }}
                          >
                            {I18nRails.t("client.profile.change_password")}
                          </Button>
                        </form>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <InfoOutlinedIcon color="primary" />
                        <h5 className="custom-accordion-header">
                          {I18nRails.t("display.terms.terms_of_use.title")}
                        </h5>
                      </AccordionSummary>
                      <AccordionDetails className="column-flex">
                        <TermsAndConditions />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      onClick={(e) => logout(basket)}
                      style={{ cursor: "pointer" }}
                    >
                      <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <ExitToApp color="primary" />
                        <h5 className="custom-accordion-header">
                          {I18nRails.t("client.profile.logout.heading")}
                        </h5>
                      </AccordionSummary>
                      <AccordionDetails className="column-flex"></AccordionDetails>
                    </Accordion>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppLayout>
    </Grid>
  );
};

export default withParent(AppProfile);
