export const SUPPORTED_CITIES = ["Islamabad", "Lahore", "Karachi"];

// export const BASE_URL = "http://192.168.1.202:3000";
// export const BASE_URL = "http://localhost:3000";
const urls ={
  managerURL: 'https://www.foodnerdpos.com',
  customerURL: 'https://www.foodnerd.pk'
}
const URL = (process.env.EXPO_PUBLIC_APP_URL && process.env.EXPO_PUBLIC_APP_URL === 'manager') ? urls.managerURL : urls.customerURL;
export const BASE_URL = URL;
// export const BASE_URL = "https://staging.foodnerd.pk";
export const MANAGER_URL = BASE_URL + '/manager'
export const BASE_API = BASE_URL + '/api';
export const GOOGLE_API_KEY = 'AIzaSyCxS9xO1KzWzCro9w4B0bGXCJ3l6GCeyj8';
export const PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.menumonkey.app';
export const APP_STORE_URL =
  'https://apps.apple.com/pk/app/foodnerd-food-is-social/id1160211615';

export const HTTPStatusCode = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Unused',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Entity Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  422: 'Unprocessable Entity',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  511: 'Network Authentication Required',
  520: 'Web server is returning an unknown error',
  522: 'Connection timed out',
  524: 'A timeout occurred',
};

export const REGEX = {
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  STRING: /^[a-zA-Z]*$/g,
};

export const constants = {
  ORDER_MODE_TYPE: {
    EAT_IN: "eat_in",
    EAT_IN_2: "eat-in",
    TAKE_AWAY: "take_away",
    DELIVERY: "delivery"
  },
  PAYMENT_METHODS: {
    CASH: "CASH",
    CREDIT_CARD: "CREDIT CARD",
    DNA: "DNA",
    NEARPAY: "NEARPAY",
    SPLIT_PAYMENT: "SPLIT PAYMENT",
    JAZZ_CASH: "JAZZ CASH",
    EASYPAISA: "EASY PAISA",
  },
  PaymentTypes: {
    CREDIT_CARD: "Payment::Ccard",
    CARD: "Payment::Card",
    CASH: "Payment::Cash",
    DNA: "Payment::DnaPayment",
    NEARPAY: "Payment::Nearpay",
    JAZZCASH: "Payment::JazzCash",
    EASYPAISA: "Payment::EasyPaisa"
  },
  paymentMethodTypes: {
    CARD: "PaymentMethod::Card",
    CASH: "PaymentMethod::Cash",
  },
  FeeKinds: {
    DELIVERY_CHARGE: "delivery_charge",
    PAYMENT_CCARD: "payment_ccard",
    SERVICE_CHARGE: "service_charge"
  }
}